import clx from "classnames";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppError from "../../../components/AppError";
import AppIcon from "../../../components/AppIcon";
import Locale from "../../../components/Locale";
import { CRM_API_URL } from "../../../config";
import axios from "../../../config/axios";

export default function CompanyForm() {
  /** */

  const navigate = useNavigate();

  const URLParams = queryString.parse(useLocation().search);
  const token = URLParams?.token;

  const [error, setError] = useState(null);

  const [selected, setSelected] = useState(undefined);

  const [redirect, setRedirect] = useState(null);

  // Get Companies Mutation
  const GCM = useMutation(() => axios.get(`${CRM_API_URL}/app/app/auth/unified/companies`, { params: { token } }));

  // Company Login
  const SDM = useMutation((values) => axios.post(`${CRM_API_URL}/app/app/auth/unified/login/company`, values), {
    onSuccess: ({ error, data }) => {
      if (data?.token) return setRedirect(`http://${data?.redirect}/auth?token=${data?.token}`); // Redirect to Subdomain
      if (error) return setError(error);
    },
  });

  // Handle Company Login
  const handleLogin = () => SDM.mutate({ company: selected, token: token });

  useEffect(() => {
    GCM.mutate();
  }, []);

  return (
    <div className="flex flex-col space-y-2 justify-center h-full">
      <span className="text-xl uppercase font-light text-gray-700 mb-4">
        <Locale code="T.SELECT_SUB_ACCOUNT" />
      </span>
      <AppError error={error} />
      {GCM.data?.data?.companies?.map(({ brandName, logo, _id }) => {
        const _active = selected === _id;
        return (
          <div
            onClick={() => {
              if (_id === selected) return;
              setSelected(_id);
              setRedirect(null);
            }}
            className={clx(
              "flex items-center border cursor-pointer rounded-md h-14 px-2 justify-between",
              { "bg-gray-100": _active },
              { "border-gray-300 hover:bg-gray-100 ": !_active }
            )}
          >
            <div className="flex items-center space-x-2">
              <img src={"/logo192.png"} className="w-10 h-10 object-contain" />
              <span className="text-gray-700">{brandName}</span>
            </div>
            {_active && <AppIcon code="fa-check-circle" className="w-6 h-6 text-blue-500" />}
          </div>
        );
      })}
      {!redirect && <AppButton title="T.AUTHENTICATE" mode="primary" disabled={!selected} loading={SDM.isLoading} onClick={handleLogin} />}
      {redirect && (
        <a href={redirect} className="block w-ful">
          <AppButton title="T.OPEN_ADMIN_PANEL" className="w-full" mode="success" />
        </a>
      )}
      <AppButton title="T.BACK" mode="light" onClick={() => navigate("/")} />
    </div>
  );
}
