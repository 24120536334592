import { createContext, useState } from "react";

const AppContext = createContext();
const { Provider } = AppContext;

const AppConfigProvider = ({ children }) => {
  // Locale
  const [locale, setLocale] = useState(localStorage.getItem("locale") ?? "en");
  const changeLocale = (locale) => {
    localStorage.setItem("locale", locale);
    setLocale(locale);
  };

  return (
    <Provider
      value={{
        locale,
        changeLocale,
      }}
    >
      {children}
    </Provider>
  );
};
export { AppConfigProvider, AppContext };
