import queryString from "query-string";
import { useLocation } from "react-router-dom";
import CompanyForm from "./components/CompanyForm";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
export default function LoginPage() {
  /** */

  const URLParams = queryString.parse(useLocation().search);

  const mode = URLParams?.invite ? "register" : URLParams?.token ? "company" : "login";
  // const mode = "company";

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-gray-50">
      <div className="container mx-auto h-3/4 bg-white border rounded-md overflow-hidden shadow-lg">
        <div className="grid grid-cols-5 h-full">
          <div className="col-span-full lg:col-span-3 h-full border-r relative flex items-center justify-center">
            <img src="/login.png" className="absolute inset-0 top-0 bottom-0 h-full w-full" />
            <span className="z-10 text-white text-2xl font-light uppercase">PatientsOnBoard CRM</span>
          </div>
          <div className="col-span-full lg:col-span-2 h-full bg-white px-10">
            {mode === "login" && <LoginForm />}
            {mode === "register" && <RegisterForm />}
            {mode === "company" && <CompanyForm />}
          </div>
        </div>
      </div>
    </div>
  );
}
