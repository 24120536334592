import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "../modules/auth/LoginPage";

export default function AppRoutes() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}
