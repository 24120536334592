import { QueryClient, QueryClientProvider } from "react-query";
import { AppConfigProvider } from "./context/AppContext";
import LocaleProvider from "./context/LocaleProvider";
import "./index.css";
import AppRoutes from "./routes/AppRoutes";

export default function App() {
  // React Query
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppConfigProvider>
        <LocaleProvider>
          <AppRoutes />
        </LocaleProvider>
      </AppConfigProvider>
    </QueryClientProvider>
  );
}
