import clx from "classnames";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

const _MODES = {
  primary: "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white",
  "light-primary": "opacity-70 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 text-white",
  success: "bg-green-600 hover:bg-green-700 focus:ring-green-500 text-white",
  "light-success": "bg-white text-green-600 border border-green-600 hover:text-white hover:bg-green-600 focus:ring-green-500 text-white",
  warning: "bg-amber-600 hover:bg-amber-700 focus:ring-amber-500 text-white",
  "light-warning": "opacity-70 bg-amber-600 hover:bg-amber-700 focus:ring-amber-500 text-white",
  danger: "bg-red-600 hover:bg-red-700 focus:ring-red-500 text-white",
  "light-danger": "bg-white hover:bg-red-500 focus:ring-red-500 text-red-700 hover:text-white",
  light: "border border-gray-300 bg-white hover:bg-gray-100 focus:ring-gray-500 text-gray-500",
  icon: "bg-white hover:bg-gray-100 rounded-md focus:ring-0 text-gray-500 hover:text-gray-700",
};

export default function AppButton({ loading, disabled = false, title, tooltip = "", mode = "light", icon, href, className = "", onClick = () => {} }) {
  const intl = useIntl();

  const navigate = useNavigate();

  const _disabled = disabled || loading;

  return (
    <button
      disabled={disabled || loading}
      onClick={() => {
        if (href) return navigate("new");
        else return onClick();
      }}
      title={tooltip}
      className={`flex items-center justify-center text-sm px-3 h-12 rounded-md ${_MODES[mode]} ${_disabled ? "opacity-75" : "cursor-pointer"} ${className}`}
    >
      <>
        {loading ? <Spinner size="sm" /> : <></>}
        {title ? <span className={clx({ "ml-2": icon || loading })}>{intl.formatMessage({ id: title ?? "-" })}</span> : <></>}
      </>
    </button>
  );
}
