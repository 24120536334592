import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppError from "../../../components/AppError";
import { CRM_API_URL } from "../../../config";
import axios from "../../../config/axios";
import AppInput from "./AppInput";
export default function LoginForm() {
  /** */

  const navigate = useNavigate();

  // Submit Data Mutation
  const SDM = useMutation((values) => axios.post(`${CRM_API_URL}/app/app/auth/unified/login/personal`, values), {
    onSuccess: ({ data }) => {
      if (data?.token) navigate(`/?token=${data?.token}`);
    },
  });

  // Values
  const [values, setValues] = useState({ email: "", password: "" });

  const handlePersonalLogin = () => SDM.mutate({ email: values?.email, password: values?.password });

  return (
    <div className="flex flex-col space-y-2 px-12 justify-center h-full">
      <span className="text-xl uppercase font-light text-gray-700 mb-4">SignIn</span>
      <AppError error={SDM.data?.error} />
      <form>
        <div className="flex flex-col space-y-2">
          <AppInput type="text" placeholder="Email" value={values?.email} onChange={(value) => setValues((x) => ({ ...x, email: value }))} />
          <AppInput type="password" placeholder="Password" value={values?.password} onChange={(value) => setValues((x) => ({ ...x, password: value }))} />
          <AppButton mode="primary" className="w-full" title="T.LOGIN" loading={SDM.isLoading} onClick={handlePersonalLogin} />
        </div>
      </form>
    </div>
  );
}
