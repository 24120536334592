import { Field, Formik } from "formik";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import AppButton from "../../../components/AppButton";
import AppError from "../../../components/AppError";
import Locale from "../../../components/Locale";
import RowInput from "../../../components/form/RowInput";
import { CRM_API_URL } from "../../../config";
import axios from "../../../config/axios";
import AppInput from "./AppInput";

export default function RegisterForm({}) {
  /** */

  const navigate = useNavigate();

  const URLParams = queryString.parse(useLocation().search);

  const [error, setError] = useState(null);

  // Get Invitation Mutation
  const GIM = useMutation(() => axios.get(`${CRM_API_URL}/app/app/auth/unified/invite/${URLParams.invite}`), {
    onSuccess: ({ error, data }) => {
      if (error) setError(error);
    },
  });

  // Submit Data Mutation (Register)
  const SDM = useMutation((values) => axios.post(`${CRM_API_URL}/app/app/auth/unified/register`, values), {
    onSuccess: ({ error, data }) => {
      if (data?.token) return navigate(`/?token=${data?.token}`);
      if (error) setError(error);
    },
  });

  const handleSubmit = (values) => {
    if (!["firstName", "lastName", "email", "password", "confirm_password"].every((code) => Boolean(values?.[code]))) return setError("T.ALL_FIELDS_REQUIRED");
    if (values["password"] !== values["confirm_password"]) return setError("T.PASSWORDS_MISMATCH");
    return SDM.mutate(values);
  };

  const loading = GIM.isLoading || SDM.isLoading;
  const disabled = loading || GIM.data?.error;

  useEffect(() => {
    GIM.mutate();
  }, [URLParams.invite]);

  return (
    <div className="flex flex-col space-y-2 justify-center h-full">
      <span className="text-xl uppercase font-light text-gray-700 mb-4">
        <Locale code="T.REGISTER" />
      </span>
      <AppError error={error} />
      <Formik enableReinitialize initialValues={GIM.data?.data ?? {}} onSubmit={handleSubmit}>
        {({ handleSubmit }) => {
          return (
            <div className="flex flex-col space-y-2">
              <Field autoComplete="new-password" name="email" component={RowInput} FormElement={AppInput} disabled type="text" placeholder="T.EMAIL" />
              <Field
                autoComplete="new-password"
                name="firstName"
                component={RowInput}
                FormElement={AppInput}
                disabled={disabled}
                type="text"
                placeholder="T.FIRST_NAME"
              />
              <Field
                autoComplete="new-password"
                name="lastName"
                component={RowInput}
                FormElement={AppInput}
                disabled={disabled}
                type="text"
                placeholder="T.LAST_NAME"
              />
              <Field
                autoComplete="new-password"
                name="password"
                component={RowInput}
                FormElement={AppInput}
                disabled={disabled}
                type="password"
                placeholder="T.PASSWORD"
              />
              <Field
                autoComplete="new-password"
                name="confirm_password"
                component={RowInput}
                FormElement={AppInput}
                disabled={disabled}
                type="confirm_password"
                placeholder="T.CONFIRM_PASSWORD"
              />
              <AppButton mode="primary" title={loading ? "T.PLEASE_WAIT" : "T.REGISTER"} loading={loading} disabled={disabled} onClick={handleSubmit} />
              <AppButton mode="light" title="T.BACK" onClick={() => navigate("/")} />
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
