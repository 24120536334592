import _axios from "axios";
const axios = _axios.create();
axios.defaults.headers.Accept = "application/json";
axios.interceptors.response.use(
  (response) => {
    if (response.data.status !== 200) {
      console.log(response?.data?.error);
      return { error: response?.data?.error?.message };
    }
    return { data: response.data.data };
  },
  (error) => {
    console.log(error);
    return { error: "Unexpected Error" };
  }
);
export default axios;
